var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"-mt-top",staticStyle:{"background-color":"#fff"},attrs:{"no-gutters":""}},[_c('Headers',{attrs:{"img":require('@/assets/icons/student-exam.jpg'),"polygon":"","title":"knowledgeTest","description":"masterDegreeOfCommunicationArtsAndManagementInnovation","mdColor":"background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #FF7C84 0%, rgba(244, 212, 68, 0.92) 51.56%, rgba(244, 212, 68, 0.25) 100%);","smColor":"background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, #FF7C84 0%, rgba(244, 212, 68, 0.92) 51.56%, rgba(244, 212, 68, 0.25) 100%);"},scopedSlots:_vm._u([{key:"above",fn:function(){return [_c('v-chip',{staticStyle:{"border":"solid 1px"},attrs:{"outlined":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("currentStudent"))+" ")])]},proxy:true}])}),_c('v-row',{staticClass:"mt-12 ma-10 tw-w-[100%] tw-relative",attrs:{"justify":"center"}},[_c('img',{staticClass:"tw-absolute tw-w-[40%] tw-max-w-[500px] tw-right-0 tw-bottom-[-250px]",attrs:{"src":"/scholarship/bottom-right-polygon.png"}}),_c('v-col',{staticClass:"tw-relative",attrs:{"cols":"12","sm":"12","md":"7"}},[(_vm.item)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"600","font-size":"28px","line-height":"42px"}},[_vm._v(" "+_vm._s(_vm.$store.state.lang == "th" ? _vm.item.title_th : _vm.item.title_en)+" ")])]),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('span',{staticClass:"texttitle"},[_vm._v(_vm._s(_vm.$t("admissions")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"textdesc"},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$store.state.lang == 'th'
                  ? _vm.item.registation_th
                  : _vm.item.registation_en
              )}})])]),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('span',{staticClass:"texttitle"},[_vm._v(_vm._s(_vm.$t("payment")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"textdesc"},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$store.state.lang == 'th'
                  ? _vm.item.pay_method_th
                  : _vm.item.pay_method_en
              )}})])]),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('span',{staticClass:"texttitle"},[_vm._v(_vm._s(_vm.$t("dateAndTimeTestLocation")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"textdesc"},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$store.state.lang == 'th'
                  ? _vm.item.day_place_th
                  : _vm.item.day_place_en
              )}})])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"texttitle"},[_vm._v(_vm._s(_vm.$t("announcementOfTestResults"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"textdesc"},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$store.state.lang == 'th'
                  ? _vm.item.announce_result_th
                  : _vm.item.announce_result_en
              )}})])])],1):_vm._e()],1),_c('v-col',{staticClass:"tw-py-[56px] sm:tw-py-[64px] md:tw-py-[100px] tw-relative",attrs:{"cols":"12","sm":"12","md":"4"}},[(_vm.downloads.length > 0)?_c('v-row',{attrs:{"align":"right","justify":"start"}},[_c('span',{staticClass:"mt-2",staticStyle:{"font-weight":"600","font-size":"28px","line-height":"42px"}},[_vm._v(_vm._s(_vm.$t("documentDownload")))]),_vm._l((_vm.downloads),function(i){return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onClickView(i.path)}}},[_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('v-icon',{attrs:{"color":"#2ab3a3"}},[_vm._v("mdi-file-document-outline ")])],1),_c('v-col',{attrs:{"cols":"11","md":"11"}},[_c('span',{staticStyle:{"font-style":"normal","font-weight":"500","font-size":"16px","line-height":"24px","color":"#2ab3a3"}},[_vm._v(" "+_vm._s(i.name)+" ")])])],1)],1)})],2):_vm._e()],1)],1),_c('Footers',{staticStyle:{"z-index":"1"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }